import { AppSettings } from "../models/AppSettings";

export const getAppSettings = async (): Promise<AppSettings | undefined> => {
  try {
    const result = await fetch(`settings/environment.json?cb=${Date.now()}`)
      .then((response) => response.json())
      .then((response) => response as AppSettings);

    result.loaded = true;
    return result;
  } catch (err) {
    console.error(err);
  }

  return undefined;
};
