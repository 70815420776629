import * as React from "react";
import * as Styled from "./Button.styles";

export interface ButtonProps {
  /** The content to render on the button */
  children?: React.ReactNode;
  /** Disable button */
  disabled?: boolean;
  /** Classname to override styling */
  className?: string;
  /** Eventhandler for onclick event */
  onClick?: React.MouseEventHandler;
  /** Eventhandler for onmousedown event */
  onMouseDown?: React.MouseEventHandler;
}

export const Button = ({ children, disabled, className, onClick, onMouseDown }: ButtonProps) => {
  return (
    <Styled.Button
      disabled={disabled}
      className={className}
      onClick={onClick}
      onMouseDown={onMouseDown}
    >
      {typeof children === "string" ? <span>{children}</span> : children}
    </Styled.Button>
  );
};
