/* tslint:disable */
/* eslint-disable */
/**
 * ZwitchRef.Api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface SignedUrlResponse
 */
export interface SignedUrlResponse {
    /**
     * 
     * @type {string}
     * @memberof SignedUrlResponse
     */
    publicUrl: string;
    /**
     * 
     * @type {string}
     * @memberof SignedUrlResponse
     */
    signature: string;
    /**
     * 
     * @type {Date}
     * @memberof SignedUrlResponse
     */
    expiresAt: Date;
}

export function SignedUrlResponseFromJSON(json: any): SignedUrlResponse {
    return SignedUrlResponseFromJSONTyped(json, false);
}

export function SignedUrlResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): SignedUrlResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'publicUrl': json['publicUrl'],
        'signature': json['signature'],
        'expiresAt': (new Date(json['expiresAt'])),
    };
}

export function SignedUrlResponseToJSON(value?: SignedUrlResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'publicUrl': value.publicUrl,
        'signature': value.signature,
        'expiresAt': (value.expiresAt.toISOString()),
    };
}

