import { AppSettings } from "../models/AppSettings";
import { Configuration, AuthApi, UserInfo } from "./generated";

export const getUserInfo = async (settings: AppSettings): Promise<UserInfo | undefined> => {
  if (settings.API_ROOT === "") {
    return;
  }

  const config = new Configuration({
    basePath: settings.API_ROOT,
    credentials: "include",
  });

  try {
    return await new AuthApi(config).authUserGet();
  } catch (err) {
    console.error(err);
  }
};
