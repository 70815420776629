
import { createTheme } from "@mui/material";
import { createGlobalStyle } from "styled-components";

export const defaultTheme = createTheme({
  borderRadius: "4px",
  typography: {
    fontFamily: "'Roboto','Open Sans', sans-serif",
    fontDisplay: "optional",
    fontStretch: "normal",
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: 23,
    lineHeight: 34,
    maxWidth: "60ch",
  },
  palette: {
    common: {
      success: "#9ac137",
      error: "#eb595e",
      inactive: "#d0cdcd",
      active: "#1d9ed9",
      selected: "#15759f",
      highlight: "#ffe400",
      dragTargetFrame: "#9d9d9c",
      dragTargetBox: "#e5e5e5",
      backgroundFrame: "#ddf1fa",
    }
  },
});

export const GlobalStyle = createGlobalStyle`
  html * {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    font-family: ${defaultTheme.typography.fontFamily};
  }
  html,
  body,
  #root {
    height: 100%;
    font-size: ${defaultTheme.typography.fontSize};
  }
`;

declare module '@mui/material/styles/createTheme' {
  interface ThemeOptions {
    borderRadius: string;
  }
}

declare module '@mui/material/styles/createTypography' {
  interface TypographyOptions {
    fontDisplay: string;
    fontStretch: string;
    fontStyle: string;
    fontWeight: string;
    lineHeight: number;
    maxWidth: string
  }
}

declare module '@mui/material/styles/createPalette' {
  interface CommonColors {
    success: string;
    error: string;
    inactive: string;
    active:string;
    selected: string,
    highlight: string,
    dragTargetFrame: string,
    dragTargetBox: string,
    backgroundFrame: string,
  }
}