import { AppSettings } from "../models/AppSettings";
import { Configuration, ImageApi } from "./generated";

export const getSignedUrl = async (
  elvisAssetId: string,
  settings: AppSettings
): Promise<string> => {
  if (settings.API_ROOT === "") {
    return "";
  }

  const config = new Configuration({
    basePath: settings.API_ROOT,
    credentials: "include",
  });

  try {
    const result = await new ImageApi(config).apiImageSignatureGet();
    if (result) {
      return `${result.publicUrl}preview/${elvisAssetId}?${result.signature}`;
    }
  } catch (err) {
    console.error(err);
  }

  return "";
};
