import { useContext, useState, useEffect } from "react";
import { AppContext } from "../../../providers/AppProvider";
import { getSignedUrl } from "../../../services";

interface ImageItemProps {
  assetId: string;
  alt: string;
}

export const ElvisImage = ({ assetId, alt }: ImageItemProps) => {
  const settings = useContext(AppContext);
  const [imageUrl, setImageUrl] = useState("");

  useEffect(() => {
    if (imageUrl === "") {
      (async () => {
        setImageUrl(await getSignedUrl(assetId, settings));
      })();
    }
  }, [imageUrl, assetId, settings]);

  return <>{imageUrl !== "" && <img src={imageUrl} alt={alt} />}</>;
};
