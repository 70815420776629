import styled, { ThemeProvider } from "styled-components";
import { Button } from "./components/atoms/Button";
import { defaultTheme, GlobalStyle } from "./theme";
import { Stack, Paper } from "@mui/material";
import { ElvisImage } from "./components/atoms/ElvisImage/";
import { useApp } from "./providers/AppProvider";

const Item = styled(Paper)(({ theme }) => ({
  ...theme.typography.fontSize,
  padding: theme.spacing(1),
  display: "flex",
  justifyContent: "center",
  columnGap: theme.spacing(1),
  color: theme.palette.text.secondary,
}));

function App() {
  const { isAuthenticated, user, login, logout } = useApp();

  return (
    <ThemeProvider theme={defaultTheme}>
      <GlobalStyle />
      <Stack spacing={2}>
        <Item>
          {isAuthenticated && user ? (
            <h2>Welcome {user?.name}</h2>
          ) : (
            <h2>Welcome to the Zwitch reference spa</h2>
          )}
        </Item>
        <Item>
          {isAuthenticated ? (
            <ElvisImage assetId="5c5PQrC0KTM8LeKdDsT3Qs" alt="ElvisExample" />
          ) : (
            <div>Login to show image from Elvis</div>
          )}
        </Item>
        <Item>
          {isAuthenticated ? (
            <Button onClick={logout}>Logout</Button>
          ) : (
            <Button onClick={login}>Login</Button>
          )}
        </Item>
      </Stack>
    </ThemeProvider>
  );
}

export default App;
