import styled from "styled-components";

export const Button = styled.button`
  min-width: 60px;
  min-height: 60px;
  & span {
    padding: 8px;
    line-height: 24px;
  }
  cursor: pointer;
  appearance: none;
  border: 0 none;
  font-weight: bold;
  font-size: 20px;
  color: ${(p) => p.theme.palette.common.white};
  border-radius: ${(p) => p.theme.borderRadius};
  background-color: ${(p) => p.theme.palette.common.active};
  outline: 0 none;

  &:active {
    background-color: ${(p) => p.theme.palette.common.selected};
  }
  &:disabled {
    background-color: ${(p) => p.theme.palette.common.inactive};
  }
  & svg {
    display: block;
  }
`;
